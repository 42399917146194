<template>
  <a-alert message="Bootstrap-vue is disabled" type="info" show-icon>
    <template #description
      >The "bootstrap-vue" plugin has not yet migrated to Vue3.
      <a
        href="https://github.com/bootstrap-vue/bootstrap-vue"
        target="_blank"
        rel="noopener noreferrer"
        class="vb__utils__link"
        >Visit github repository for details</a
      ></template
    >
  </a-alert>
</template>
