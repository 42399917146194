<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder"><vb-hidden-kit-bootstrap /></div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHiddenKitBootstrap from '@/@vb/widgets/Hidden/KitBootstrap'

export default {
  name: 'VbBootstrap',
  components: {
    VbHiddenKitBootstrap,
  },
}
</script>
